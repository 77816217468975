<template>
  <div class="register">

    <vue-picker 
      class="picker"
      :data="pickData"
      @cancel="cancel"
      @confirm="confirm"
      :showToolbar="true"
      :maskClick="true"
      :visible.sync="pickerVisible"
    />

    <div class="input">
      <p class="input-name">手机号</p>
      <div class="input-border">
        <input maxlength="11" placeholder="手机号" type="text" v-model="mobile" />
      </div>
    </div>

    <div class="input">
      <p class="input-name">密码</p>
      <div class="input-border">
        <input placeholder="密码" type="password" v-model="password" />
      </div>
    </div>

    <div class="input">
      <p class="input-name">姓名</p>
      <div class="input-border">
        <input placeholder="姓名" type="text" v-model="username"/>
      </div>
    </div>

    <div class="input" @click="pickerOpen(1)">
      <p class="input-name">身份</p>
      <div class="input-border">
        <input placeholder="身份" type="text" v-model="identity" disabled/>
      </div>
    </div>

    <div class="input" @click="pickerOpen(2)" v-show="identity == '学生'">
      <p class="input-name">年级</p>
      <div class="input-border">
        <input placeholder="年级" type="text" v-model="grade" disabled/>
      </div>
    </div>

    <div class="input" @click="pickerOpen(3)">
      <p class="input-name">省份</p>
      <div class="input-border">
        <input placeholder="省份" type="text" v-model="province" disabled/>
      </div>
    </div>

    <div class="input" @click="pickerOpen(4)">
      <p class="input-name">城市</p>
      <div class="input-border">
        <input placeholder="城市" type="text" v-model="city" disabled/>
      </div>
    </div>

    <div class="input" @click="pickerOpen(5)">
      <p class="input-name">区县</p>
      <div class="input-border">
        <input placeholder="区县" type="text" v-model="area" disabled/>
      </div>
    </div>
    
    <div class="button">
      <div class="btn" @click="register">注册</div>
    </div>
    <Tips :tip="msg" />
  </div>
</template>

<script>

  export default {
    beforeMount() {
      document.title = '明九章-注册';
    },
    mounted(){
      this.getData();
    },
    data () {
      return {
        mobile: '', // 手机号
        password: '', // 密码
        username: '', // 名称
        grade: null, // 年级
        identity: '学生', // 老师还是学生
        realList: ['学生', '老师'],
        provinceCode: '', // 省份
        province: '',
        provinceCodeList: [],
        cityCode: '', // 城市
        city: '',
        cityCodeList: [],
        areaCode: '', // 区县
        area: '',
        areaCodeList: [],
        gradeList: ['一年级', '二年级', '三年级', '四年级','五年级','六年级','七年级','八年级'],
        currId: 1,
        pickerVisible: false,
        pickData: [],
        result: '',
        msg: '',
      }
    },
    methods: {
        getData(id = 1, currId = 3) {
          this.$http.get(`/anonymous/sysCity/getCityListByParentCode//${id}`, {
          })
          .then((res) => {
            if (currId == 3) {
              this.provinceCodeList = res.data.data;
            } else if (currId == 4){
              this.cityCodeList = res.data.data;
            } else if (currId == 5){
              this.areaCodeList = res.data.data;
            }
          }).catch((err) => {
              this.validErrorInfo = err.errorMsg;
          })
        },
        onConfirm(value, index) {
          Toast(`当前值：${value}, 当前索引：${index}`);
        },
        onChange(picker, value, index) {
          Toast(`当前值：${value}, 当前索引：${index}`);
        },
        onCancel() {
          Toast('取消');
        },
        register() {
          if (this.mobile === '') {
            alert('手机号不能为空');
            return;
          }
          if (this.password === '') {
            alert('密码不能为空');
            return;
          }
          if (this.password.length < 6) {
            alert('密码长度要大于等于6位');
            return;
          }
          if (this.username === '') {
            alert('姓名不能为空');
            return;
          }
          if (this.identity == '学生') {
            if (this.grade === '') {
              alert('年级不能为空');
              return;
            }
          }
          if (this.provinceCode === '') {
            alert('省份不能为空');
            return;
          }
          if (this.cityCode === '城市不能为空') {
            alert('');
            return;
          }
          if (this.areaCode === '') {
            alert('区县不能为空');
            return;
          }

          let info = {
            mobile: this.mobile,
            password: this.password,
            username: this.username,
            grade: this.grade,
            identity: this.identity,
            provinceCode: this.provinceCode,
            cityCode: this.cityCode, 
            areaCode: this.areaCode,
          }

          if (this.identity == '老师') {
            delete info.grade
          }
          this.$http.post('/api/register', info)
          .then((res) => {
            localStorage.setItem('ng-data', JSON.stringify(res.data.data));
            localStorage.setItem('ng-token', res.data.data.token);
            this.msg = '注册成功';
            setTimeout(() => {
              this.$router.push('/main/home');
            })
          }).catch((err) => {
              this.validErrorInfo = err.errorMsg;
          })

        },
        pickerOpen(id) {
          let tdata = [];
          let data = [];
          this.currId = id;
          if (id === 1) {
            data = this.realList;
          } else if (id === 2) {
            data = this.gradeList;
          } else if (id === 3) {
            data = this.provinceCodeList;
          } else if (id === 4) {
            if (this.provinceCode === '') {
              alert('请先选择省份');
              return;
            }
            data = this.cityCodeList;
          } else if (id === 5) {
            if (this.cityCode === '') {
              alert('请先选择城市');
              return;
            }
            data = this.areaCodeList;
          } 
          for (let i = 0; i< data.length; i ++) {
            tdata.push({
              label: data[i]?.name || data[i],
              value: data[i]?.code || data[i],
            })
          }
          this.pickData = [ tdata ];
          this.pickerVisible = true;
        },
        cancel () {
          console.log('cancel')
          this.result = 'click cancel result: null'
        },
        confirm (res) {
          if (this.currId === 1) {
            this.identity = res[0].value;
            console.log(this.identity);
          } else if (this.currId === 2) {
            this.grade = res[0].value;
          } else if (this.currId === 3) {
            this.provinceCode = res[0].value;
            this.province = res[0].label;
            this.cityCode = '';
            this.areacity = '';
            this.areaCode = '';
            this.area = ''
            this.getData(res[0].value, 4)
          } else if (this.currId === 4) {
             this.cityCode = res[0].value;
             this.city = res[0].label;
             this.areaCode = '';
             this.area = ''
             this.getData(res[0].value, 5)
          } else if (this.currId === 5) {
              this.area = res[0].label;
              this.areaCode = res[0].value;
          }
        }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .register {
    padding-top: 0.2rem;
    .input {
      padding: 0 0.3rem;
      color: #79cdca;
      font-size: 0.3rem;
      margin-bottom: 0.3rem;
      .input-name {
        margin-bottom: 0.12rem;
        font-size: 0.26rem;
      }
      .input-border {
        border: 1px solid #79cdca;
        height: 0.9rem;
        border-radius: 0.12rem;
        display: flex;
        align-items: center;
        input {
          color: #333333;
          height: 0.75rem;
          width: 92%;
          margin: 0 auto;
          display: block;
          font-size: 0.3rem;
        }
      }
    }
    .button {
      padding: 0 0.3rem 1rem;
      box-sizing: border-box;
      margin: 0.5rem auto;
      border-radius: 0.12rem;
      color: white;
      overflow: hidden;
      .btn {
        background-color: #79cdca;
        width: 100%;
        height: 0.9rem;
        border-radius: 0.12rem;
        text-align: center;
        line-height: 0.9rem;
        font-size: 0.32rem;
        cursor: pointer;
      }
    }
    // .picker {
    //    left: 50%;
    //    transform: translateX(-50%);
    //    max-width: 7.5rem;
    // }
  }
  input:disabled {
    background-color: #fff;
    color: #666;
    opacity: 1;
  }
</style>
